body {
  margin: 0;
}

p,
ul,
ol {
  font-size: 1rem;
}

li {
  margin-bottom: 8px;
}
